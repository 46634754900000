import React from "react";
import { PropTypes } from "prop-types";
import { MrbRouterLink } from "mrb/core/infrastructure/router";
import {
    MrbButton,
    MrbFieldInput,
    MrbFieldCheckbox,
    MrbRecaptcha,
    MrbEditFormLayout,
    MrbRecaptchaPrivacyPolicy,
    MrbFieldTextArea,
} from "mrb/components";
import { PasswordFieldInfo } from "common/components";
import { defaultTemplate } from "common/hoc";
import { observer } from "mobx-react";
import { MrbFieldRadioButtons } from "baasic-react-bootstrap/src";

function ArtistRegisterTemplate({ artistRegisterViewStore, t }) {
    const { form, success, recaptchaViewStore, producerTypes } = artistRegisterViewStore;

    return (
        <React.Fragment>
            <div className="c-membership__wrapper">
                <div className="c-membership__cta">
                    <p>
                        Already a member?{" "}
                        <MrbRouterLink routeName="master.public.membership.login">Sign in now</MrbRouterLink>.
                    </p>
                </div>
                <div className="c-membership__form">
                    <h1 className="c-membership__form__title">{t("REGISTER.TITLE")}</h1>
                    <MrbEditFormLayout
                        t={t}
                        form={form}
                        submitLabel="REGISTER.BUTTONS.SIGN_UP"
                        submitDisabled={success}
                        footer={<RegisterFooter form={form} t={t} submitDisabled={success} />}
                    >
                        <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("firstName")} />
                        <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("lastName")} />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("email")}
                            autoComplete="email"
                        />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("confirmEmail")}
                            autoComplete="email"
                        />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("password")}
                            autoComplete="new-password"
                        />
                        <PasswordFieldInfo field={form.$("password")} />
                        <MrbFieldInput
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("confirmPassword")}
                            autoComplete="new-password"
                        />
                        <MrbFieldRadioButtons
                            classNameOverride="t"
                            inputClassNameOverride="c-input--radio"
                            rowView={false}
                            t={t}
                            id="artist-type-control"
                            name="artist-type-control"
                            options={producerTypes}
                            field={form.$("producerType")}
                        />
                        {form.$("producerType").value === 1 ? (
                            <ArtistContentUsageAgreement />
                        ) : (
                            <PodcasterContentUsageAgreement />
                        )}
                        <MrbFieldCheckbox
                            t={t}
                            field={form.$("contentUsageAgreement")}
                            labelRender={(props) => <ContentUsageAgreementLabel {...props} />}
                        />
                        <MrbFieldInput classNameExtend="c-input--outline" t={t} field={form.$("phoneNumber")} />
                        <MrbFieldTextArea
                            classNameExtend="c-input--outline"
                            t={t}
                            field={form.$("artistRegisterMessage")}
                        />
                        <MrbFieldCheckbox
                            t={t}
                            field={form.$("privacyPolicy")}
                            labelRender={(props) => <PrivacyPolicyLabel {...props} />}
                        />
                        <RegistrationStatus artistRegisterViewStore={artistRegisterViewStore} t={t} />
                    </MrbEditFormLayout>
                    <MrbRecaptcha t={t} store={recaptchaViewStore} />
                </div>
            </div>
        </React.Fragment>
    );
}

ArtistRegisterTemplate.propTypes = {
    artistRegisterViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

function PrivacyPolicyLabel({ id }) {
    return (
        <label htmlFor={id} className="u-mar--bottom--sml">
            By signing up, I accept the NakiRadio <a href="https://nakiradio.com/terms-of-use/">Terms and Conditions</a>{" "}
            and acknowledge the
            <a href="https://nakiradio.com/privacy-policy/"> Privacy Policy</a>.
        </label>
    );
}

function ArtistContentUsageAgreement() {
    return (
        <div className="c-card--primary c-card--content-agreement">
            <p>By joining Naki as an Artist, you agree to the following terms:</p>
            <ol className="c-card--content-agreement__list">
                <li>
                    <p>
                        <strong>Publishing of Audio Content.</strong> Artist hereby grants to Naki permission during the
                        term of this Agreement to publish Content via Naki’s Device and App.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Artist’s Intellectual Property Rights.</strong> Artist exclusively owns all rights,
                        title and interest in and to Content, though it grants Naki via this Agreement the right to host
                        and publish such Content via Naki’s Device and App. Artist warrants that it has all rights and
                        consents necessary to allow Naki to use the Content.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Payment for Content.</strong> Naki will apportion 70% of each subscriber’s payment for
                        Premium content to Artists. The distribution of such revenue to individual Artists will be
                        determined by the accumulated duration of streams equal to or exceeding one minute of Artist’s
                        content in proportion to the accumulated duration of streams equal to or exceeding one minute of
                        all Artists' Content throughout the billing period.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Data and Tracking.</strong> Naki shall track usage of Content to determine revenue
                        distribution to Artists. The accuracy of such data shall be determined by Naki at its sole
                        discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Popularity and Charts.</strong> Naki shall have the right to publicize the popularity
                        and statistics of Content as determined by Naki’s internal tracking data. The accuracy of such
                        data shall be determined by Naki at its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Content Review and Availability.</strong> Naki shall have the right to review and
                        approve any Content prior to publishing on Naki’s Device or App. Naki may remove Content at any
                        time at its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Promotion of Content.</strong> Artist grants Naki license to promote and publish samples
                        of Content in a limited manner on Naki’s Device, App and/or website, as well as license to use
                        Artist’s branding in marketing and promotional materials.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Termination.</strong> Either Party may terminate this Agreement at any time with written
                        notice to the other party.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Survival.</strong> Upon termination of this Agreement, Section 2 shall survive this
                        Agreement.
                    </p>
                </li>
            </ol>
        </div>
    );
}

function PodcasterContentUsageAgreement() {
    return (
        <div className="c-card--primary c-card--content-agreement">
            <p>By joining Naki as a Podcaster, you agree to the following terms:</p>
            <ol className="c-card--content-agreement__list">
                <li>
                    <p>
                        <strong>Publishing of Audio Content.</strong> Podcaster hereby grants to Naki permission during
                        the term of this Agreement to publish Content via Naki’s Device and App.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Podcaster’s Intellectual Property Rights.</strong> Podcaster exclusively owns all
                        rights, title and interest in and to Content, though it grants Naki via this Agreement the right
                        to host and publish such Content via Naki’s Device and App. Podcaster warrants that that it has
                        all rights and consents necessary to allow Naki to use the Content.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Content Import, Statistics and Data.</strong> Content shall be imported and stored at
                        Naki for the purpose of distribution via Naki’s Device and/or App. Engagement statistics for
                        Content distributed by Naki shall will be made available to Podcaster via the Podcaster’s Naki
                        account. The accuracy of such data shall be determined by Naki at its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Popularity and Charts.</strong> Naki shall have the right to publicize the popularity
                        and statistics of Content as determined by Naki’s internal tracking data. The accuracy of such
                        data shall be determined by Naki at its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Content Review and Availability.</strong> Naki shall have the right to review and
                        approve any Content prior to publishing on Naki’s Device or App. Naki may remove Content at any
                        time at its sole discretion.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Promotion of Content.</strong> Podcaster grants Naki license to promote and publish
                        samples of Content in a limited manner on Naki’s Device, App or website, as well as license to
                        use Podcaster’s branding in marketing and promotional materials.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Termination.</strong> Either Party may terminate this Agreement at any time with written
                        notice to the other party.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Survival.</strong> Upon termination of this Agreement, Section 2 shall survive this
                        Agreement.
                    </p>
                </li>
            </ol>
        </div>
    );
}

function ContentUsageAgreementLabel({ id }) {
    return (
        <label htmlFor={id} className="u-mar--bottom--sml">
            I have read and I accept the Content Usage Agreement
        </label>
    );
}

const RegistrationStatus = observer(function RegistrationStatus({ artistRegisterViewStore, t }) {
    if (artistRegisterViewStore.success) {
        return (
            <div className="c-validation--success u-mar--top--sml">
                <p className="c-validation__label">{t("REGISTER.SUCCESS_MESSAGE")}</p>
            </div>
        );
    }
    return null;
});

const RegisterFooter = observer(function LoginFooter({ form, submitDisabled, t }) {
    const label = t("REGISTER.BUTTONS.SIGN_UP");
    return (
        <div className="c-form__footer">
            <MrbButton
                type="submit"
                aria-label={label}
                aria-busy={form.submitting || form.validating}
                label={label}
                className="c-btn c-btn--primary c-btn--med u-width--100"
                disabled={submitDisabled}
            />
            <div className="u-separator--primary u-mar--bottom--lrg u-mar--top--lrg"></div>

            <MrbRecaptchaPrivacyPolicy className="u-type--sml u-type--center" />
        </div>
    );
});

export default defaultTemplate(ArtistRegisterTemplate);
